<template>
  <div data-test-key="advertise-page">
    <h1>The crypto industry's favorite job board</h1>

    <div style="max-width: 800px; margin: auto;">
      <h2>Hire With Us</h2>

      <p>Hooking job seekers with simplicity and savings, TheJobTap is the new hub for hassle-free hiring. 
        By streamlining the recruitment process and reducing costs, TheJobTap makes advertising open positions easy and effective. 
        With affordable packages and a commitment to generating quality leads, TheJobTap takes the pain out of recruitment.</p>

      <p>Make things as simple as they can be</p>
      <p>Our goal is to get you quality leads/applications</p>
      <p>Specifc for niche recruitment.. a big flaw is not finding the right talent </p>
      <p>Specialising in a niche and focusing on one sector to bring you the talent you want to attract</p>

      <h2>Our services</h2>
        <p>Add your job feed</p>
      <!-- <h2>Boost job applications</h2> -->

      <h2>Why hire with TheJobTap?</h2>
      <p>Hiring the right talent</p>
      <p>Deployed to web, </p>
      <p>Take advantage of our job distribution network, google jobs, emails, advertisements, telegram </p>
      <p>relevant jobs to ensure a high CTR from email subscriptions</p>
  </div>
</div>
</template>

<script>
export default{
  data() {
    return {
    };
  },
  mounted() {
    document.title = 'Hire with us - TheJobTap.com'
  }
};
</script>