<template>
    <div data-test-key="email-subscription-page">
      <h1>Get Notified</h1>
  
      <h2>Email subscriptions (no account required)</h2>
      <p>Turn off your email subs whenever you want! also restart them. </p>
      <p>Easy to manage email subscriptions - Show email sub managment.</p>
      <p>Manage your email subscription and turn it off with ease!</p>
      <p>Can subscribe to any level of the search allowing us to send very relevant jobs</p>
  
      <h2>Telegram</h2>
      <p>Turn off your email subs whenever you want! also restart them. Like a tap. We will provide you the jobs.</p>

      <h2>Discord</h2>
      <p>Turn off your email subs whenever you want! also restart them. Like a tap. We will provide you the jobs.</p>

      <h2>Twitter</h2>
      <p>Turn off your email subs whenever you want! also restart them. Like a tap. We will provide you the jobs.</p>

      <h2>LinkedIn</h2>
      <p>Turn off your email subs whenever you want! also restart them. Like a tap. We will provide you the jobs.</p>
      
      <h2>Facebook</h2>
      <p>Turn off your email subs whenever you want! also restart them. Like a tap. We will provide you the jobs.</p>

    </div>
  </template>
  
  <script>
  export default{
    data() {
      return {
      };
    },
    mounted() {
      document.title = 'Get Notified - TheJobTap.com'
    }
  };
  </script>