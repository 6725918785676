<template>
  <nav data-test-key="navigation">
    <router-link to="/web3-jobs/">Search</router-link> |
    <router-link to="/job-notifications/">Get notfied</router-link> |
    <!-- <router-link to="/hiring-companies/">Hiring Companies</router-link> | -->
    <router-link to="/articles/">Articles</router-link> |
    <router-link to="/hire-with-us/">Hire with us</router-link>
    <!-- <router-link to="/learn-web3/">Learn Web3</router-link> -->
    <!-- <router-link to="/statistics/">Statistics</router-link> | -->
    <!-- <div v-if="companyUserStore == null" style="margin-top: 10px">
      <span>Company: </span>
      <router-link to="/company-register/">Register</router-link> |
      <router-link to="/company-login/">Login</router-link>      
      <br />
    </div>
    <div v-if="companyUserStore != null">
      <p>Company Account Pages</p>
      <router-link to="/company-dashboard/" data-test-key="nav-company-dashboard">Dashboard</router-link> | 
      <router-link v-if="companyUserStore.IsAdmin && companyUserStore.HasSetCompanyDetails" to="/company-account/manage/" data-test-key="nav-company-manage">Manage Company | </router-link>
      <router-link v-if="companyUserStore.IsAdmin && companyUserStore.HasSetCompanyDetails" to="/company-account/manage-users/" data-test-key="nav-company-manage-members">Manage Members | </router-link>
      <router-link v-if='companyUserStore.IsAdmin && companyUserStore.HasSetCompanyDetails' to="/company-account/subscription/" data-test-key="nav-company-manage-subscription">Subscription | </router-link>
      <router-link v-if='companyUserStore.IsAdmin && companyUserStore.HasSetCompanyDetails' to="/company-account/invoice-history/" data-test-key="nav-company-billing-history">Invoice History | </router-link>
      <a data-test-key="nav-company-logout" v-on:click="handleLogout">Logout</a>
   </div> -->
  </nav>
</template>

<script lang="ts">

import { companyUserAPI } from "@/api/companyUser";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import { mapGetters } from "vuex"; 
import router from "@/router";
import store from "@/store/vuex";

const pageStatus = useStatus();
const log = useErrorLogger();

export default {
  name: "Navigation",
 computed :{
    ...mapGetters(['companyUserStore'])
 },
    methods: {
    async handleLogout() {
      // remove from local storage
      localStorage.removeItem('jbpw-auth-token');
      // remove from store
      store.dispatch('companyUserStore', null)
      // destroy token
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await companyUserAPI.logout();
          if(result){
            router.push('/');
          }
        })
      );
      if (!result.success) {

      }  
    },
  }
}

</script>