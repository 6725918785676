<template>
    <div data-test-key="email-subscription-page">
      <div v-if="tag == null">
<p>not found</p>
      </div>
      <div v-else>
        <h1 >Web3 {{ tag.Name }} jobs</h1>

        <div v-if="pageContentFound == false" style="margin-bottom: 60px">
          <div>
            <p><a style="cursor: pointer; color: blue;" v-on:click="this.$router.push('/web3-jobs/?tags=' + tag.Name)">Search jobs like this</a></p>
            <p><a style="cursor: pointer; color: blue;" v-on:click="this.$router.push('/web3-jobs/?tags=' + tag.Name + '&isRemote=true')">Search Remote jobs like this</a></p>
          </div>
          <p>{{ tag.Name }} content missing -> Generate generic placeholder text here</p>
        </div>

        <div v-if="pageContentFound == true">

          <img :src=pageContent.MainImage style="display: inline-block; height: 120px;" />

          <div style="display: inline-block;" >
            <p><a style="cursor: pointer; color: blue;" v-on:click="this.$router.push('/web3-jobs/?tags=' + tag.Name)">Search jobs like this</a></p>
            <p><a style="cursor: pointer; color: blue;" v-on:click="this.$router.push('/web3-jobs/?tags=' + tag.Name + '&isRemote=true')">Search Remote jobs like this</a></p>
          </div>
          
          <!-- Article: Content -->
          <WysiwygViewer :viewerText="this.pageContent.Content" style="margin-bottom: 60px" />

          <!-- Product Recommendations -->
          <div style="margin-bottom: 60px">
            <h3>Product Recommendations</h3>
            <p>
              <a :href="pageContent.RecommendedProducts.Product1.Link">{{ pageContent.RecommendedProducts.Product1.Title }}</a>
            </p>
            <p>
              <a :href="pageContent.RecommendedProducts.Product2.Link">{{ pageContent.RecommendedProducts.Product2.Title }}</a>
            </p>
          </div>
        </div>

      <div v-if="jobListingsLoading">
        <img class="_imgLoader" src="@/assets/images/loading.gif"/>
      </div> 
      <div v-else-if="jobListings != ''" style="display: grid; margin-bottom: 60px">
        <h3>Most popular Web3 <span v-if="jobListings.length === 1">job</span><span v-else>jobs</span> for {{ tag.Name }}</h3>

        <div style="padding: 15px;" v-bind:key="jobListing.Identifier" v-for="jobListing in jobListings">
          <div style="">
            <div style=""><a v-on:click="viewJobListingPageClick(jobListing.CompanyName, jobListing.Identifier)" style="cursor: pointer;"><b>{{ jobListing.Title }}</b></a></div>
            <div style=""><a>{{ jobListing.Salary.From.toLocaleString('en-US', { style: 'currency',currency: 'USD'}) }} - {{ jobListing.Salary.To.toLocaleString('en-US', { style: 'currency',currency: 'USD'}) }}</a></div>
            <div style="">
              <a>{{ jobListing.CompanyName }}</a> | 
              <a>{{ jobListing.Location }}</a> | 
              <a>{{ jobListing.ContractType }}</a> | 
              <a>{{ jobListing.AttendanceRequirement }}</a>
            </div>
            <div style="line-height: 25pt; min-width:450px; display:inline; overflow: hidden;">
              <li style="display:inline-block;" v-bind:key="categoryName" v-for="categoryName in jobListing.CategoryNames" >
                <span style="border: 1px solid black; padding: 5px; font-size: 9pt; margin-right: 3px;"
                :class="{ active: tag.Name == categoryName }" >
                <a :href="/web3-jobs/ + categoryName">{{ categoryName }}</a>
              </span>
              </li>
            </div>
          </div>
        </div>
        <!-- Email Subscription Form -->
        <EmailSubscriptionForm 
          :handleResetForm="this.resetEmailForm"
          :isRemote=null
          :companyName=null
          :tags="this.tag.Name"
          :location=null
          :contractType=null
          :minSalary=null>
        </EmailSubscriptionForm>
    </div> 

    <div v-if="articlesLoading">
        <img class="_imgLoader" src="@/assets/images/loading.gif" style="margin-bottom:800px"/>
      </div>
      <div v-else>
          <h3 v-if="articles.length > 0" >Articles and Learning resources for {{ tag.Name }} </h3>
          <div v-bind:key="article" v-for="article in articles" style="display:block; padding: 10px; max-width: 400px; margin-left: auto; margin-right: auto;">
          <img :src=article.MainImage style="height: 180px;"/>
          <br/>
          <div style="display:inline-block; padding: 10px; cursor: pointer;">
            <a href="#" @click="handleArtlicleClick(article.Slug)"><b>{{ article.Title }}</b></a>
            <p>{{ article.Excerpt }}</p>
          </div>
        </div>
      </div>
    </div>

        <!-- Frequently Asked Questions -->
        <div v-if="pageContentFound == true" style="margin-bottom: 60px">
          <h3>Frequently Asked Questions</h3>
            <div class="accordion">
              <details>
                <summary>{{ pageContent.FAQS.Question1.Question }}</summary>
                <p>{{ pageContent.FAQS.Question1.Answer }}</p>
              </details>
              <details>
                <summary>{{ pageContent.FAQS.Question2.Question }}</summary>
                <p>{{ pageContent.FAQS.Question2.Answer }}</p>
              </details>
		        </div>
          </div>

  </div>

</template>
  
  <script>

import { contentAPI } from "@/api/content";
import { jobListingCategoryAPI } from "@/api/jobListingCategory";
import { jobListingAPI } from "@/api/jobListing";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import EmailSubscriptionForm from "@/components/Forms/EmailSubscriptionForm.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import WysiwygViewer from '@/components/WysiwygViewer.vue'

const pageStatus = useStatus();
const log = useErrorLogger();

  export default{
    name: "JobListingSearchCategoryView",
  components : {
    EmailSubscriptionForm, FeedbackNotification, WysiwygViewer
  }, 
  data() {
    return {
      notification : {
        text : "",
        level : "",
      },
      tag : null,
      queryTagName : this.$route.params.category,
      jobListings : [],
      jobListingTotalCount : 0,
      jobListingsLoading : true,      
      resetEmailForm: false,
      articles : null,      
      articleResultCount : 0,
      articleShowingCount : 0,
      pageContent : null,
      pageContentFound: null
    }
  },
  created()
  {
      this.getJobListingCategory();
      this.getJobListingCategoryContent();
      this.handleSearchJobListings();
      this.handleSearchArticles();
  },
  mounted() {
    document.title = this.$route.params.category + ' jobs - TheJobTap.com'
  },
  methods: {
    async handleArtlicleClick(articleName){
    this.$router.push('/articles/' + articleName + "/");
  },
    async viewJobListingPageClick(companyName, identifier) {
      this.$router.push('/hiring-companies/' + companyName + '/' + identifier + '/');
    },
    async getJobListingCategory() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingCategoryAPI.getCategoryByName(this.queryTagName);
          if (result == null){
            alert("not found");
          }
          else
          {
            this.tag = result;
          }
        })
      );
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };        
      }
    },
    async handleSearchJobListings() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingAPI.search(this.queryTagName, '', false, null, '', '', '', '', 2, 1);
          this.jobListings = result.Results;
          this.jobListingTotalCount = result.ResultCount;
        }));
      this.jobListingsLoading = false;
      if (!result.success) 
      {
        this.jobListingsLoading = false;
        this.notification = { text : result.data, level : "error" };        
      }
    },
    async handleSearchArticles() {
      this.articlesLoading = true;
      const result = await log.catchPromise(() =>
          pageStatus.observe(async () => {
            const result = await contentAPI.searchArticles(this.queryTagName);
            this.articles = result.Results;
            this.articleResultCount = result.ResultCount;
            this.articleShowingCount = result.Results.length; 
            await this.createQueryString();
          })
        );
        this.articlesLoading = false;
        if (!result.success) 
        {
          this.articlesLoading = false;
          this.notification = { text : result.data, level : "error" };        
        }
  },
  async getJobListingCategoryContent() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await contentAPI.getJobListingCategoryContent(this.queryTagName);
          if (result == null){
            this.pageContentFound = false;
          }
          else
          {
            this.pageContentFound = true;
            this.pageContent = result;
          }
        })
      );
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };        
      }
    },
}
};
  </script>

  <style lang="scss">


.accordion {
  margin: auto;
	max-width: 500px;
	width: calc(100% - 20px);
}
details {
	background-color: #fff;
	margin-bottom: 10px;
	// filter: drop-shadow(5px 5px 0px #59c9e8);
}
// details:hover {
// 	 filter: drop-shadow(5px 5px 4px #0A0A0A);
// }
details > summary {
	color: #2c3e50;
	padding: 5px 10px;
	font-size: 1.3em;
	cursor: pointer;
}
details > p {
	padding: 5px 10px 10px 20px;
	color: #2c3e50;
	font-size: 1.1em;
}
</style>