import { httpClient } from "./http-client";
const basePath = "/site";

export const siteAPI = {
  submitContactUsRequest: async (subject: string, emailAddress: string, contactText: string) => 
  {
    const url = basePath + "/submitcontactusrequest";
    return await httpClient.post(url, {
      Subject: subject,
      Emailaddress: emailAddress,
      ContactText: contactText,
    });
  },
  getSiteMap: async () => 
  {
    const url = basePath + "/sitemap";
    return await httpClient.get(url);
  },
  getLandingPage: async (slug: string) => 
    {
      const url = basePath + "/landingpage?slug=" + slug;
      return await httpClient.get(url);
    },
};