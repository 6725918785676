<template>
  <div data-test-key="articles-page">
    <h1>Articles</h1>
    <p>Covering everything from how to land a job in the web3 industry, to useful web3 industry resources and the latest web3 news.</p>
    <div v-if="pageLoading">
      <img class="_imgLoader" src="@/assets/images/loading.gif"/>
    </div>
    <div v-else>
      <!-- Articles: All Tags -->
      <div style="line-height: 25pt; min-width:450px; display:inline; overflow: hidden;">
        <ul style="line-height: 30px; padding-inline-start: 0px">
          <li style="display:inline;">
            <span style="border: 1px solid black; padding: 5px; font-size: 9pt; margin-right: 5px; cursor: pointer;" 
              :class="{ active: this.search.tags == '' }"
              v-on:click="handleCategoryClick(null)"><a>All </a></span>
          </li>
          <li style="display:inline-block;" v-bind:key="category" v-for="category in categories" >
          <span style="border: 1px solid black; padding: 5px; font-size: 9pt; margin-right: 3px; cursor: pointer;"
            :class="{ active: search.tags.includes(category) }" 
                v-on:click="handleCategoryClick(category)">
                  <a>{{ category }}</a>
          </span>
        </li>
      </ul>
      </div>
      <!-- Search: Meta info and Sort by -->
      <div style="margin-bottom: 15px;margin-top: 15px;">Showing {{ this.articleShowingCount }} of {{ this.articleResultCount }} Articles</div>
      <!-- Page: FeedbackNotification -->
      <FeedbackNotification style="margin-bottom: 15px;" 
      v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/>
      <!-- Articles: Results -->
      
      <div v-if="articlesLoading">
        <img class="_imgLoader" src="@/assets/images/loading.gif" style="margin-bottom:800px"/>
      </div>
      <div v-else v-bind:key="article" v-for="article in articles" style="display:block; padding: 10px; max-width: 400px; margin-left: auto; margin-right: auto;">
        <img :src=article.MainImage style="height: 180px;"/>
        <br/>
        <div style="display:inline-block; padding: 10px; cursor: pointer;">
          <a href="#" @click="handleArtlicleClick(article.Slug)"><b>{{ article.Title }}</b></a>
          <br/>
          <div style="line-height: 25pt; min-width:450px; display:inline; overflow: hidden;">
            <li style="display:inline-block;" v-bind:key="category" v-for="category in article.Categories" >
              <span style="border: 1px solid black; padding: 5px; font-size: 9pt; margin-right: 3px; cursor: pointer;" 
                :class="{ active: search.tags.includes(category) }" 
                v-on:click="handleCategoryClick(category)">
                  <a>{{ category }}</a>
                </span>
            </li>
          </div>
          <p>{{ article.Excerpt }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { contentAPI } from "@/api/content";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import WysiwygViewer from '@/components/WysiwygViewer.vue'
import FeedbackNotification from "@/components/FeedbackNotification.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default{
  name: "Articles",
  components : {
    FeedbackNotification
  }, 
  data() {
    return {
      search : {
        tags: this.$route.query.tags !== undefined ? this.$route.query.tags : [],
      },
      pageLoading : true,
      articlesLoading : true,
      categories : [],
      articles : null,      
      articleResultCount : 0,
      articleShowingCount : 0,
      notification : {
        text : "",
        level : "",
      }
    };
  },
  created(){
    this.getCategories();
    this.searchArticles();
  },
  mounted() {
    document.title = 'Articles - TheJobTap.com'
  },
  methods: {
  async handleArtlicleClick(articleName){
    this.$router.push('/articles/' + articleName + "/");
  },
  async handleCategoryClick(categoryName) {
      if (categoryName == null)
      {
         this.search.tags = [];
         await this.searchArticles();
         return;
      }
      if(!Array.isArray(this.search.tags))
      {
         this.search.tags = [categoryName];
         await this.searchArticles();
         return;
      }
      const categoryNames = [];
      this.search.tags.forEach(element => {
        if (element != categoryName)
        {
          categoryNames.push(element);
        }
      });
      if (!this.search.tags.includes(categoryName))
      {
        categoryNames.push(categoryName);
      }
      this.search.tags = categoryNames;
      await this.searchArticles();
    },
    async createQueryString() {
      const query = Object.assign({}, this.$route.query);
      query.tags = this.search.tags;
      await this.$router.push({ query });
    },
  async getCategories() {
    const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await contentAPI.getCategories();
          this.categories = result;
        })
      );
      this.pageLoading = false;
      if (!result.success) 
      {
        this.notification = { text : result.data, level : "error" };        
      }
  },
  async searchArticles() {
    this.articlesLoading = true;
    const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await contentAPI.searchArticles(this.search.tags);
          this.articles = result.Results;
          this.articleResultCount = result.ResultCount;
          this.articleShowingCount = result.Results.length; 
          await this.createQueryString();
        })
      );
      this.articlesLoading = false;
      if (!result.success) 
      {
        this.notification = { text : result.data, level : "error" };        
      }
  },
}
};
</script>