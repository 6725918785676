import { JobListingCategory } from "@/domain/jobListingCategory/jobListingCategory";
import { JobListingCategoryGroup } from "@/domain/jobListingCategory/jobListingCategoryGroup";
import { httpClient } from "./http-client";
const basePath = "/job-listing-category";

export const jobListingCategoryAPI = {
  getCategories: async () => 
  {
    const url = basePath + "/getall";
    const response = await httpClient.get(url);
    return response.data as JobListingCategory;
  },
  getCategoryByName: async (categoryName: string) => 
    {
      const url = basePath + "/getbyname?categoryName=" + categoryName;
      const response = await httpClient.get(url);
      return response.data as JobListingCategory;
    },
  getCategoryGroups: async () => 
  {
    const url = basePath + "/getallgroups";
    const response = await httpClient.get(url);
    return response.data as JobListingCategoryGroup;
  },
};