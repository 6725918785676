<template>
   <button style="cursor: pointer;" data-test-key="button-job-listing-apply" class="_jobListingApplyButton" v-on:click="handleApplicationSubmit">apply here</button>
</template>

<script>
import { jobListingApplicationAPI } from "@/api/jobListingApplication";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";

const pageStatus = useStatus();
const log = useErrorLogger();

export default {
  props: ["jobListingIdentifier"],
  components: {
  },
  methods: {

  },
  computed: {
    
  },
  mounted() {
    
  },
  methods: {

  async handleApplicationSubmit() 
    {
      this.isApplicationSubmitted = true;
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          alert("applicantSessionId")
          const result = await jobListingApplicationAPI.submit(this.jobListingIdentifier, "SESSIONID");

          if (result.ApplicationType == "Email")
          {
            window.location.href = "mailto:" + result.ApplicationTypeValue + "?subject=" + result.JobListingTitle + "&body=message%20goes%20here";
          }
          if (result.ApplicationType == "WebsiteLink")
          {
            window.open(result.ApplicationTypeValue, '_blank').focus();
          }
        })
      );
      if (!result.success) {
        //this.notification = { text : result.data, level : "error" };        
        alert(result);
      }
    },
  }
}
</script>