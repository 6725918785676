<template>
  <div data-test-key="contact-us-page">
    <h1>Contact</h1>
    <p>If you've got a question, a comment, a proposal or anything else, we'd love to hear from you!</p>
    <p>You can get in touch by filling out the form below or contacting us at hello@thejobtap.com</p>
     <ContactUsForm />
  </div>
</template>

<script>

import ContactUsForm from "@/components/Forms/ContactUsForm.vue";

export default {  
  name: "ContactUsView",
  components : {
    ContactUsForm
  }, 
  mounted() {
    document.title = 'Contact - TheJobTap.com'
  }
};
</script>