<template>
    <a>
        <img src="https://imagedelivery.net/EWJlSj3VmuhdddwuuB6Q0A/d3717885-7e3a-4baf-4b4f-de0e5993cc00/public" style="height: 130px; display:inline-block;">
        <!-- <p><i><b>empowering the web3 job space</b></i></p> -->
    </a>
    <div>
        <h1>Search web3 jobs</h1>
        <p>Global search</p>
        <p>Entry points into searches</p>
        <a href="/web3-jobs/?isRemote=true">search remote jobs</a>
        <br/>
        <a href="/web3-jobs/">search jobs</a>
    </div>
</template>
  