import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { "data-test-key": "navigation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createVNode(_component_router_link, { to: "/web3-jobs/" }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("Search")
      ])),
      _: 1
    }),
    _cache[4] || (_cache[4] = _createTextVNode(" | ")),
    _createVNode(_component_router_link, { to: "/job-notifications/" }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createTextVNode("Get notfied")
      ])),
      _: 1
    }),
    _cache[5] || (_cache[5] = _createTextVNode(" | ")),
    _createVNode(_component_router_link, { to: "/articles/" }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [
        _createTextVNode("Articles")
      ])),
      _: 1
    }),
    _cache[6] || (_cache[6] = _createTextVNode(" | ")),
    _createVNode(_component_router_link, { to: "/hire-with-us/" }, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [
        _createTextVNode("Hire with us")
      ])),
      _: 1
    })
  ]))
}