<template>

<footer data-test-key="footer" class="footer">
    <router-link class="_footerLink" to="/contact-us/">Contact</router-link> | 
    <router-link class="_footerLink" to="/policies/">Policies</router-link> |
    <router-link class="_footerLink" to="/sitemap/">SiteMap</router-link>
    <br/>
    <br/>
    <a class="_footerLink" href="/web3-remote-jobs/">Remote Web3 Jobs</a> |
    <a class="_footerLink" href="/web3-location-usa/">Web3 Jobs in the USA</a> |
    <a class="_footerLink" href="/web3-tech-jobs/">Tech Web3 Jobs</a> |
    <a class="_footerLink" href="/web3-non-tech-jobs/">Non Tech Web3 Jobs</a> |
    <a class="_footerLink" href="/web3-internship-jobs/">Internship Web3 Jobs</a>
    <br/>
    <br/>
    <p>Popular searches</p>
    <router-link class="_footerLink" to="/web3-remote-jobs/">Developer Web3 Jobs</router-link> |
    <router-link class="_footerLink" to="/web3-marketing-jobs/">Marketing Web3 Jobs</router-link> |
    <router-link class="_footerLink" to="/web3-legal-jobs/">Legal Web3 Jobs</router-link> |
    <router-link class="_footerLink" to="/web3-finance-jobs/">Finance Web3 Jobs</router-link> |
</footer>

</template>

<style scoped>

</style>

<script lang="ts">


</script>