<template>
<div data-test-key="company-search-page">
    <h1>Web3 Hiring Companies</h1>

    <FeedbackNotification style="margin-bottom: 15px;" 
      v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/>

    <div v-if="companiesLoading">
      <img class="_imgLoader" src="@/assets/images/loading.gif"/>
    </div>
    <div v-else-if="companies != ''">
      <table style="margin-left: auto; margin-right: auto; padding: 10px;">
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr v-bind:key="company.Name" v-for="company in companies">
          <td style="width:150px;"><img :src=company.ImageLogoUrl style="height: 50px; width: 150px;" /></td>
          <td style="width:150px;"><b>{{ company.Name }}</b></td>
          <td style="width:150px;">
            <button style="cursor: pointer;" v-on:click="companyClick(company.Name)">
              view {{ company.ActiveJobListingCount }} 
              <span v-if="company.ActiveJobListingCount === 1">job</span>
              <span v-else>jobs</span>
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { companyAPI } from "@/api/company";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import FeedbackNotification from "@/components/FeedbackNotification.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default{
  components: { FeedbackNotification },
  data() {
    return {
      companies : [],
      companiesLoading : true,      
      notification : {
        text : "",
        level : "",
      },
    };
  },
 created(){
    this.handleSearchCompanies()
 },
  mounted() {
    document.title = 'Hiring Companies - TheJobTap.com'
  },
  methods: {
    async handleSearchCompanies() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await companyAPI.search();
          this.companies = result.Results;
        })
      );
      this.companiesLoading = false;
      if (this.ResultCount === 0)
      {
        this.notification = { text : "No company results found", level : "notification" };        
      }
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };          
      }
    },
    async companyClick(companyName) {
      this.$router.push('/hiring-companies/' + companyName);
    },
  },
};
</script>