<template>
<div data-test-key="company-page">
  <div v-if="companyLoading">
    <img class="_imgLoader" src="@/assets/images/loading.gif"/>
  </div> 
  <div v-else-if="this.company !== null">
    <div class="_divBreadcrumb">
      <p>
        <a href="#" v-on:click="viewHiringCompaniesPageClick">Hiring Companies</a> / {{ company.Name }}
      </p>
    </div>
    <h1>{{ company.Name }}</h1>
    <img :src=company.ImageLogoUrl style="height: 70px;" />
    <WysiwygViewer :viewerText="this.company.Description" />
    <h3>{{ company.ActiveJobListingCount }} Web3 <span v-if="company.ActiveJobListingCount === 1">job</span><span v-else>jobs</span> listed by {{ company.Name }}</h3>
    <div v-if="jobListingsLoading">
      <img class="_imgLoader" src="@/assets/images/loading.gif"/>
    </div> 
    <div v-else-if="jobListings != ''" style="display: grid;">
        <div style="padding: 15px;" v-bind:key="jobListing.Identifier" v-for="jobListing in jobListings">
          <div style="">
            <div style=""><a v-on:click="viewJobListingPageClick(jobListing.CompanyName, jobListing.Identifier)" style="cursor: pointer;"><b>{{ jobListing.Title }}</b></a></div>
            <div style="">
              <a v-if="jobListing.Salary.From != ''">{{ jobListing.Salary.From.toLocaleString('en-US', { style: 'currency',currency: 'USD'}) }} - {{ jobListing.Salary.To.toLocaleString('en-US', { style: 'currency',currency: 'USD'}) }}</a>
            </div>
            <div style="">
              <a>{{ jobListing.CompanyName }}</a> | 
              <a>{{ jobListing.Location }}</a> | 
              <a>{{ jobListing.ContractType }}</a> | 
              <a>{{ jobListing.AttendanceRequirement }}</a>
            </div>
            <div style="line-height: 25pt; min-width:450px; display:inline; overflow: hidden;">
              <li style="display:inline-block;" v-bind:key="categoryName" v-for="categoryName in jobListing.CategoryNames" >
                <span style="border: 1px solid black; padding: 5px; font-size: 9pt; margin-right: 3px;">
                  <a>{{ categoryName }}</a>
                </span>
              </li>
            </div>
            <div>
              <!-- <button style="cursor: pointer;" v-on:click="viewJobListingPageClick(jobListing.CompanyName, jobListing.Identifier)">view</button> -->
              <!-- <JobListingApplyButton style="cursor: pointer;"
                :applicationType="jobListing.ApplicationType" 
                :ApplicationTypeValue="jobListing.ApplicationTypeValue"
                :jobListingTitle="jobListing.Title"
                :jobListingIdentifier="jobListing.Identifier"/>-->
            </div> 
          </div>
      </div>
    </div> 
    <div v-else-if="company.ActiveJobListingCount == 0">
      <FeedbackNotification style="margin-bottom: 15px;" 
      feedbackText="No results" 
      feedbackLevel="notification"/>
    </div>   
    <div v-else>
      <FeedbackNotification style="margin-bottom: 15px;" 
      v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/>
    </div>
  </div>
</div>
</template>

<script>
import { companyAPI } from "@/api/company";
import { jobListingAPI } from "@/api/jobListing";
import { jobListingApplicationAPI } from "@/api/jobListingApplication";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import WysiwygViewer from '@/components/WysiwygViewer.vue';
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import JobListingApplyButton from "@/components/JobListingApplyButton.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default {
  components: { WysiwygViewer, FeedbackNotification, JobListingApplyButton },
  data() {
    return {
      company : null,
      companyLoading : true,
      jobListings : [],
      jobListingsLoading : true,      
      notification : {
        text : "",
        level : "",
      },
    };
  },
 created(){
    this.getByName();
 },
  mounted() {

  },
  methods: {
    async viewJobListingPageClick(companyName, identifier) {
      this.$router.push('/hiring-companies/' + companyName + '/' + identifier + '/');
    },
    async viewHiringCompaniesPageClick() {
      this.$router.push('/hiring-companies/');
    },
    async getByName() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await companyAPI.getByName(this.$route.params.companyname);
          this.company = result;

          document.title = this.company.Name + ' - TheJobTap.com';
          this.handleSearch();
        })
      );

      this.companyLoading = false;

      if (!result.success) {
        this.notification = { text : result.data, level : "error" };        
      } 
    },
  async handleSearch() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingAPI.search('', this.$route.params.companyname, false , null, '');
          this.jobListings = result.Results;
        })
      );
      this.jobListingsLoading = false;
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };        
      }
    },
    async handleApplicationSubmit(applicationType, applicationTypeValue, jobListingIdentifier) {

      if(applicationType = 1){
        // email
         alert('applicationType: [' + applicationType + '] applicationTypeValue: [' +applicationTypeValue + ']')
      }
      else if(applicationType = 2) {
        // website click
         alert('applicationType: [' + applicationType + '] applicationTypeValue: [' +applicationTypeValue + ']')
      }
      this.isApplicationSubmitted = true;
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingApplicationAPI.submit(jobListingIdentifier);
          //this.$router.push({name: 'userIndex', query: { page: '123' } });
        })
      );
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };          
      }
    },
  },
};
</script>