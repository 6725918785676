<template>
  <div data-test-key="email-subscription-page">
    <div v-if="pageContentFound == null">
      <p>loading</p>
    </div>
    <div v-else-if="pageContentFound == false">
      <p>not found</p>
    </div>
    <div v-else>
      <h1>{{ pageContent.Title }}</h1>

        <img :src=pageContent.MainImage style="display: inline-block; height: 120px;" />

        <!-- Product Recommendations -->
        <div v-if="pageContent.SearchLinks != null" style="margin-bottom: 60px">
          <h3>Links to search </h3>
          <p>
            <a :href="pageContent.SearchLinks.Link1.Link">{{ pageContent.SearchLinks.Link1.Title }}</a>
          </p>
          <p>
            <a :href="pageContent.SearchLinks.Link2.Link">{{ pageContent.SearchLinks.Link2.Title }}</a>
          </p>
        </div>

        <!-- Product Recommendations -->
        <div v-if="pageContent.RecommendedProducts != null" style="margin-bottom: 60px">
          <h3>Product Recommendations </h3>
          <p>
            <a :href="pageContent.RecommendedProducts.Product1.Link">{{ pageContent.RecommendedProducts.Product1.Title }}</a>
          </p>
          <p>
            <a :href="pageContent.RecommendedProducts.Product2.Link">{{ pageContent.RecommendedProducts.Product2.Title }}</a>
          </p>
        </div>

        <!-- Article: Content -->
        <WysiwygViewer :viewerText="this.pageContent.Content" style="margin-bottom: 60px" />

      <!-- Email Subscription Form -->
      <EmailSubscriptionForm 
        :handleResetForm="this.resetEmailForm"
        :isRemote=null
        :tags=[]
        :location=null
        :contractType=null
        :minSalary=null>
      </EmailSubscriptionForm>

      <!-- Frequently Asked Questions -->
      <!-- <div v-if="pageContentFound == true" style="margin-bottom: 60px">
        <h3>Frequently Asked Questions</h3>
          <div class="accordion">
            <details>
              <summary>{{ pageContent.FAQS.Question1.Question }}</summary>
              <p>{{ pageContent.FAQS.Question1.Answer }}</p>
            </details>
            <details>
              <summary>{{ pageContent.FAQS.Question2.Question }}</summary>
              <p>{{ pageContent.FAQS.Question2.Answer }}</p>
            </details>
          </div>
        </div>-->
      </div>  
</div>

</template>
<script>

import { siteAPI } from "@/api/site";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import EmailSubscriptionForm from "@/components/Forms/EmailSubscriptionForm.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import WysiwygViewer from '@/components/WysiwygViewer.vue'

const pageStatus = useStatus();
const log = useErrorLogger();

  export default{
    name: "LandingPage",
  components : {
    EmailSubscriptionForm, FeedbackNotification, WysiwygViewer
  }, 
  data() {
    return {
      notification : {
        text : "",
        level : "",
      },
      pageContent : null,
      pageContentFound: null
    }
  },
  created()
  {
    this.getLandingPage();
  },
  mounted() {
    document.title = 'landing page - TheJobTap.com';
  },
  methods: {
  async getLandingPage() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await siteAPI.getLandingPage(this.$route.name);
          if (result == null)
          {
            this.pageContentFound = false;
          }
          else
          {
            this.pageContentFound = true;
            this.pageContent = result.data;
          }
        })
      );
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };        
      }
    },
}
};
  </script>